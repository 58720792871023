/*
Original design: This is me (dark) (v1.1 - March 13th 2012) - A free xhtml/css website template for andreasviklund.com.
For more information, see http://andreasviklund.com/templates/this-is-me-dark/

Includes:
jQuery from http://jquery.org/
Poptrox jQuery plugin by nodethirtythree: http://www.nodethirtythree.com/jquery_plugins.html
jQuery.ScrollTo and jQuery.LocalScroll by Ariel Flesler: http://flesler.blogspot.com/
Icons fom http://www.komodomedia.com/
*/

* {
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background-image: url("london-parliament-min.jpg");
  background-repeat: no-repeat no-repeat;
  background-attachment: fixed;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: black;
  color: #333;
  font: 12px Cabin, 'Noto Sans TC', sans-serif;
}

ol,
ul {
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}

a img {
  border: none;
}

a,
a:link,
a:visited {
  color: #609;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #96f;
  text-decoration: underline;
}

img a:hover {
  text-decoration: none;
}

p {
  font-family: Cabin, 'Noto Sans TC', sans-serif;
  padding: 0;
  margin: 0;
}

h2 {
  color: #609;
  font: 22px Cabin, sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
}

.left {
  float: left;
  margin: 0 15px 5px 0;
}

.right {
  float: right;
  margin: 0 0 5px 15px;
}

#map {
  height: 100%;
}

#mainwrap {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 640px;
}

#cardstyle {
  background-color: rgba(255, 255, 255, 0.6);
  height: 400px;
  margin: 100px auto 20px;
  overflow: hidden;
  padding: 6px;
  position: relative;
  width: 600px;
}

#content {
  height: 400px;
  overflow: hidden;
  position: relative;
  width: 600px;
}

#pagecontainer {
  position: relative;
  width: 9999px;
}

.section {
  float: left;
  height: 275px;
  margin-right: 50px;
  padding: 0 10px 25px;
  position: relative;
  width: 568px;
}

#menuwrap {
  margin: 0 auto;
}

#iconmenu {
  bottom: 0;
  clear: both;
  height: 50px;
  left: 90px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  width: auto;
  z-index: 100;
}

#iconmenu li {
  display: block;
  float: left;
}

#iconmenu li a {
  display: block;
  float: left;
  opacity: 0.6
}

#iconmenu li {
  padding: 0 10px;
}

#iconmenu li a.selected,
#iconmenu li a:hover,
#iconmenu li a:focus {
  opacity: 1;
}

#start {
  height: 200px;
  padding: 80px 15px 30px;
}

#start h1 {
  color: #609;
  font-size: 90px;
  font-family: Allura, 'Noto Serif TC';
  line-height: 0.9em;
  margin: 0;
  text-align: center;
  text-shadow: 0 1px 0 #609;
}

div.tagline {
  background: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  text-align: center;
  margin: 10px 0px;
  padding: 2px 10px;
}

.poptroxactive {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.poptroxactive li {
  float: left;
  height: 110px;
  margin: 0 30px 8px 0;
}

.poptroxactive li img {
  border: 3px solid #fff;
  box-shadow: 1px 1px 0px #aaa;
  cursor: pointer;
  width: 100px;
}

.poptrox_popup {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 50px #111;
  padding: 1em 1em 3em;
  position: relative;
}

.poptrox_popup .loader {
  background: url('loader.gif') top center no-repeat;
  border-radius: 8px;
  display: block;
  margin: 55px 0 0;
  text-indent: -9999em;
}

.poptrox_popup .caption {
  bottom: 1em;
  left: 1em;
  position: absolute;
}

.poptrox_popup .closer {
  bottom: 1em;
  position: absolute;
  right: 1em;
}

#social ul {
  padding: 2px 0;
}

#social li {
  display: block;
  float: left;
  height: 46px;
  margin: 0 14px 12px 0;
  overflow: hidden;
  width: 252px;
}

#social li a {
  color: #609;
  display: block;
  float: left;
  height: 32px;
  overflow: hidden;
  padding: 0 0 3px;
  width: 252px;
}

#social li a:hover,
#social li a:focus {
  color: #96F;
  text-decoration: none;
}

#social img {
  float: left;
  margin: 0 16px 0 10px;
}

#social strong {
  color: #609;
  display: block;
  font-size: 14px;
  font-weight: 700;
}

#social .external {
  color: #333;
  font-size: 16px;
}

#education .external {
  color: #333;
  font-weight: 700;
}

#address {
  border-right: 1px solid #ddd;
  float: left;
  margin-right: 20px;
  padding: 0 0 0 1px;
  width: 240px;
}

#address h3 {
  color: #609;
  font-size: 16px;
  font-weight: 700;
  padding: 8px;
}

#address h4 {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
}

#footer {
  color: #999;
  text-align: center;
  width: 100%;
}

#footer a {
  color: #999;
}

/* If you want to add styles for the custom page, you can do it by using the ID #custom, like this:
#custom h2{color:blue;}
#custom p{text-align:center;}
*/

/* Custom styles for inverting the default color scheme. See
http://andreasviklund.com/learn/this-is-me-template-with-inverted-colorscheme/
for more information. */

body.inverted {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

body.inverted #cardstyle {
  background-color: #101010;
  border: 1px solid #2a2a2a;
}

body.inverted #content {
  background-color: #181818;
}

body.inverted h2 {
  color: #eee;
}

body.inverted #start h1 {
  color: #ddd;
  text-shadow: 0 1px 0 #111;
}

body.inverted #bio img {
  border: 3px solid #111;
  box-shadow: 0px 0px 2px #000;
}

body.inverted .poptroxactive li img {
  border: 3px solid #111;
  box-shadow: 0px 0px 2px #000;
}

body.inverted .poptrox_popup {
  background-color: #222;
}

body.inverted #social li a {
  color: #ddd;
}

body.inverted #social strong {
  color: #eee;
}

body.inverted #address h3 {
  color: #eee;
}

body.inverted #address {
  border: 0;
}

body.inverted #footer,
body.inverted #footer a {
  color: #777;
}

body.inverted a {
  color: #ddd;
}

.typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0.0;
  }
}

@-webkit-keyframes typedjsBlink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1;
  }
}

.typed-fade-out {
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}

.terminal {
  margin: 20px;
  font-family: monospace;
  font-size: 16px;
  color: #22da26;
}


.command {
  width: 0%;
  white-space: nowrap;
  overflow: hidden;
}